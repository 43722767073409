<Header name="Alert"
        legend="Block meant to draw the attention to the user about something" />

<section class="alerts-demo">
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="alerts" />
</section>

<script lang="ts">
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Header from '../../docs/Header.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<div class="alert alert-warning" role="alert">
  Text
</div>
<div class="alert alert-danger" role="alert">
  Text
</div>
<div class="alert alert-success" role="alert">
  Text
</div>
<div class="alert alert-info" role="alert">
  Text
</div>
<div class="alert" role="alert">
  Text
</div>
`,
icon: `
<div class="alert alert-warning" role="alert">
  <span class="alert-icon" aria-label="attention"></span>
  Text
</div>
<div class="alert alert-danger" role="alert">
  <span class="alert-icon" aria-label="erreur"></span>
  Text
</div>
<div class="alert alert-success" role="alert">
  <span class="alert-icon" aria-label="succès"></span>
  Text
</div>
<div class="alert alert-info text-black" role="alert">
  <span class="alert-icon" aria-label="information"></span>
  Text
</div>`}
</script>

<style>
.alerts-demo :global(.example-demo) {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
