<Header name="Collapsible"
        legend="hide/reveal content back on click" />

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="collapsibles" />
</section>

<script lang="ts">
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Header from '../../docs/Header.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<details class="collapsible">
  <summary>En savoir plus</summary>
  <p class="collapsible-body">
    Ce qu'il faut savoir, je sais pas.
  </p>
</details>`}
</script>
