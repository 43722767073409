<Header name="Card" />

<Alert type="info" icon={false}>
  Cards will fill their container by default so you probably want to set a width.
  You may use the w-* utils classes for that purpose
</Alert>

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="cards" />
</section>

<script lang="ts">
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Header from '../../docs/Header.svelte'
import Alert from '../../components/Alert.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<div class="card w-xl">
  <img class="card-cover" src="https://picsum.photos/768" alt="my image">
  <div class="card-body">
    <h4 class="card-title">Deezer Famille</h4>
    <p>Ligne de description</p>

    <div class="card-price">
      <strong>5€/mois</strong>
      <p>au lieu de 5.01€/mois</p>
    </div>
  </div>

  <footer class="card-footer">
    <button class="btn btn-primary w-full">
      Ajouter au Panier
    </button>
  </footer>
</div>

<div class="card m-t-base w-l">
  <div class="card-body">
    <h4 class="card-title">Connexion</h4>

    <form class="m-t-l">
      <label class="block m-b-xs">
        <div>Login</div>
        <input type="text" />
      </label>
      <label class="block m-b-sm">
        <div>Mot de Passe</div>
        <input type="password" />
      </label>

      <button class="btn btn-primary w-full">
        envoyer
      </button>
    </form>
  </div>
</div>`,}
</script>
