<Header name="Input" legend="Text input and textarea" />

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
  <Alert type="info">
    You'll need javascript to make it work, here is a potential implementation:
    <Prism language="js" source={passwordRevealJSCode} />
  </Alert>
</section>

<section>
  <Source componentSource="forms" />
</section>

<script lang="ts">
import { onMount } from 'svelte'
import Header from '../../docs/Header.svelte'
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Alert from '../../components/Alert.svelte'
import Prism from 'svelte-prism'

onMount(() => {
  const passwordRevealSelector = '.password-reveal'
  const passwordRevealButtons = document.querySelectorAll(`${passwordRevealSelector} button`)
  console.log(passwordRevealButtons)
  passwordRevealButtons.forEach((btn) => {
    btn.addEventListener('click', () => {
      console.log('click')
      const input = btn.closest(passwordRevealSelector).querySelector('input')
      const img = btn.closest(passwordRevealSelector).querySelector('img')
      input.setAttribute(
        'type',
        input.getAttribute('type') === 'text' ? 'password' : 'text'
      )
      img.src = input.getAttribute('type') === 'text' ?
        'https://static.s-sfr.fr/assets/pictos/masquer_40_noir.svg'
        : 'https://static.s-sfr.fr/assets/pictos/afficher_40_noir.svg'
    })
  })
})
// TODO: use input-label class
const exampleCode: Record<string, string> = {
  usage: `
<div>
  <label>
    <div class="input-label">Label</div>
    <input type="text" value="Input Text" />
  </label>

  <input type="text" placeholder="Text" />
</div>
<div class="m-t-m">
  <label>
    <div class="input-label">Label</div>
    <div class="input-info">Infos supplémentaires</div>
    <input type="text" value="Input Text" />
  </label>

  <input type="email" value="..." disabled />
</div>

`,
  sizes: `
<input type="text" placeholder="block" class="input-block" />
<div class="m-t-base">
  <input type="text" placeholder="normal" />
  <input type="text" placeholder="smol" class="input-sm" />
  <input type="text" placeholder="super smol" class="input-xs" />
</div>
`,
  validation: `
<div class="m-b-l">
  <input type="email" class="valid m-b-sm" value="john.doe@sfr.fr" />
  <input type="email" class="invalid" value="oli.crt" />
</div>
<label>
  <div class="input-label">Label</div>
  <input type="text" value="Text" />
  <p class="input-text-success">Message de validation</p>
</label>
<label>
  <div class="input-label">Label</div>
  <input type="text" value="Text" />
  <p class="input-text-error">Message d'erreur</p>
</label>
`,
  textarea: `
<label>
  <div>Label</div>
  <textarea>
    Text
  </textarea>
</label>

<textarea></textarea>
`,
  'password reveal': `
<label>
  <div class="input-label">Mot de Passe</div>
  <div class="password-reveal">
    <input type="password" value="password1234:D" />
    <button type="button">
      <img src="https://static.s-sfr.fr/assets/pictos/afficher_40_noir.svg"
            alt="Afficher le mot de passe" />
    </button>
  </div>
</label>`,
};

const passwordRevealJSCode = `
const passwordRevealSelector = '.password-reveal'
const passwordRevealButtons = document.querySelectorAll(\`\${passwordRevealSelector} button\`)
passwordRevealButtons.forEach((btn) => {
  btn.addEventListener('click', () => {
    const input = btn.closest(passwordRevealSelector).querySelector('input')
    const img = btn.closest(passwordRevealSelector).querySelector('img')
    input.setAttribute(
      'type',
      input.getAttribute('type') === 'text' ? 'password' : 'text'
    )
    img.src = input.getAttribute('type') === 'text' ?
      'https://static.s-sfr.fr/assets/pictos/masquer_40_noir.svg'
      : 'https://static.s-sfr.fr/assets/pictos/afficher_40_noir.svg'
  })
})`
</script>
