<svelte:head>
  <title>TRICSS | Example</title>
</svelte:head>

<Header name="Example" />

<p>
  Here is an example of an accessible HTML structure with a navbar, a main container and a footer.
</p>

<Prism language="html" class="" source={code} />

<script lang="ts">
import Prism from 'svelte-prism'
import Example from '../docs/Example.svelte'
import Source from '../docs/Source.svelte'
import Header from '../docs/Header.svelte'

const code = `
<!DOCTYPE html>
<html lang="fr">
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width,initial-scale=1">
  <title>TRICSS</title>
  <link rel="stylesheet" href="node_modules/tricss/dist/style.css">
  <style>
    body {
      gap: 1rem;
    }
    .navbar, .footer {
      flex-shrink: 0;
    }
    main {
      flex-grow: 1;
    }
  <\/style>
</head>

<body class="flex-col">
  <header class="navbar">
    <h1 class="navbar-brand">
      <a href="#">Brand</a>
    </h1>

    <div class="navbar-menu">
      <input id="collapsible-checkbox-a" type="checkbox">
      <label for="collapsible-checkbox-a"
              class="menu-collapser"
              aria-label="Afficher/Cacher le menu">
        <span aria-hidden="true"></span>
      </label>

      <nav class="navbar-menu-start" aria-label="principal">
        <ul>
          <li><a href="#" class="active">Accueil</a></li>
          <span class="list-divider" aria-hidden="true"></span>
          <li><a href="contact.html">Contact</a></li>
        </ul>
      </nav>

      <form class="navbar-menu-end">
        <input type="search" placeholder="Rechercher" />
      </form>
    </div>
  </header>

  <main class="container m-x-sm">
    <h2 class="text-center text-primary">Title</h2>
    <p>
      Doggo ipsum adorable doggo pupperino he made many woofs you are doin me a concern shoob,
      he made many woofs vvv shoob. Very hand that feed shibe boof you are doing me a frighten very hand that feed shibe lotsa pats,
      long bois noodle horse. Shoob vvv blop puggo you are doing me the shock yapper,
      maximum borkdrive very jealous pupper smol. Ruff pats h*ck shoob super chub, vvv I am bekom fat.
      Heckin blop smol sub woofer stop it fren, maximum borkdrive shibe.
    </p>
    <p>
      Maximum borkdrive shibe heckin good boys doing me a frighten vvv,
      long water shoob mlem boof, boofers long woofer bork.
      Stop it fren very good spot big ol pupper long water shoob very taste wow he made many woofs long bois boofers.
    </p>
  </main>

  <footer class="footer">
    <nav class="footer-columns" aria-label="pied de page">
      <div class="footer-column">
        <h3 class="footer-column-header">À propos</h3>
        <ul class="footer-column-list">
          <li><a href="#">Taris</a></li>
          <li><a href="#">Remboursements</a></li>
          <li><a href="#">Informations</a></li>
          <li><a href="#">Couverture réseau mobile</a></li>
        </ul>
      </div>

      <div class="footer-column">
        <h3 class="footer-column-header">Aide</h3>
        <ul class="footer-column-list">
          <li><a href="#">Taris</a></li>
          <li><a href="#">Remboursements</a></li>
          <li><a href="#">Informations</a></li>
          <li><a href="#">Couverture réseau mobile</a></li>
        </ul>
      </div>

      <div class="footer-column">
        <h3 class="footer-column-header">Contacts</h3>
        <ul class="footer-column-list">
          <li><a href="#">Commander par téléphone</a></li>
          <li><a href="#">Service Client</a></li>
          <li><a href="#">Activer ma ligne</a></li>
        </ul>
      </div>
    </nav>

    <nav class="footer-bottom" aria-label="pied de page secondaire">
      <figure class="footer-brand">
        <img src="https://static.s-sfr.fr/assets/logos/SFR.svg" alt="logo SFR" />
      </figure>

      <ul class="footer-bottom-links">
        <li><a href="#">Offres Box</a></li>
        <span class="list-divider" aria-hidden="true"></span>
        <li><a href="#">Forfaits et Téléphones</a></li>
        <span class="list-divider" aria-hidden="true"></span>
        <li><a href="#">Pack Box + Mobile</a></li>
        <span class="list-divider" aria-hidden="true"></span>
        <li><a href="#">TV</a></li>
      </ul>

      <ul class="footer-bottom-social">
        <li><a href="#">
          1
        </a></li>
        <li><a href="#">
          2
        </a></li>
        <li><a href="#">
          3
        </a></li>
      </ul>
    </nav>
  </footer>
</body>
</html>`
</script>
