<Header name="List" />

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="lists" />
</section>

<script lang="ts">
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Header from '../../docs/Header.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<ol class="list">
  <li>A</li>
  <li>Second</li>
  <li>Third</li>
</ol>`,
colors: `
<ul class="list list-primary">
  <li>A</li>
  <li>B</li>
  <li>C</li>
</ul>
<ul class="list list-secondary">
  <li>A</li>
  <li>B</li>
  <li>C</li>
</ul>
<ul class="list list-tertiary">
  <li>A</li>
  <li>B</li>
  <li>C</li>
</ul>
<ul class="list list-info">
  <li>A</li>
  <li>B</li>
  <li>C</li>
</ul>
<ul class="list list-warning">
  <li>A</li>
  <li>B</li>
  <li>C</li>
</ul>
<ul class="list list-danger">
  <li>A</li>
  <li>B</li>
  <li>C</li>
</ul>
<ul class="list list-success">
  <li>A</li>
  <li>B</li>
  <li>C</li>
</ul>`
}
</script>
