<Header name="Divider"
        legend="Nice way to divide content" />

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="divider" />
</section>

<script lang="ts">
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Header from '../../docs/Header.svelte'

const exampleCode: Record<string, string> = {
  usage: `<div class="divider" aria-hidden="true"></div>`
}
</script>
