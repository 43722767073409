<script lang="ts">
  import Badge from '../../components/Badge.svelte'
  import Alert from '../../components/Alert.svelte';
</script>

<svelte:head>
  <title>TRICSS | Documentation | Dark Mode</title>
</svelte:head>

<header>
  <Badge type="primary">
    <h2 class="margin-bottom-small">Dark Mode</h2>
  </Badge>
</header>

<div class="divider" aria-hidden="true"></div>

<p>
  Dark mode is enabled by adding the <code>dark</code> class to your <code>html</code> tag
</p>
<Alert type="info">
  An ideal implementation is to take into account the user preference via <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme" target="_blank" rel="noreferrer">prefers-color-scheme</a> and cache theme changes.
</Alert>
