<Header name="Footer"
        legend="Responsive footer" />

<p>
</p>

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="footers" />
</section>

<script lang="ts">
import Header from '../../docs/Header.svelte'
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<footer class="footer">
  <nav class="footer-columns">
    <div class="footer-column">
      <h3 class="footer-column-header">À propos</h3>
      <ul class="footer-column-list">
        <li><a href="#">Taris</a></li>
        <li><a href="#">Remboursements</a></li>
        <li><a href="#">Informations</a></li>
        <li><a href="#">Couverture réseau mobile</a></li>
      </ul>
    </div>

    <div class="footer-column">
      <h3 class="footer-column-header">Aide</h3>
      <ul class="footer-column-list">
        <li><a href="#">Taris</a></li>
        <li><a href="#">Remboursements</a></li>
        <li><a href="#">Informations</a></li>
        <li><a href="#">Couverture réseau mobile</a></li>
      </ul>
    </div>

    <div class="footer-column">
      <h3 class="footer-column-header">Contacts</h3>
      <ul class="footer-column-list">
        <li><a href="#">Commander par téléphone</a></li>
        <li><a href="#">Service Client</a></li>
        <li><a href="#">Activer ma ligne</a></li>
      </ul>
    </div>
  </nav>

  <nav class="footer-bottom">
    <figure class="footer-brand">
      <img src="sfr.svg" alt="logo SFR" />
    </figure>

    <ul class="footer-bottom-links">
      <li><a href="#">Offres Box</a></li>
      <span class="list-divider" aria-hidden="true"></span>
      <li><a href="#">Forfaits et Téléphones</a></li>
      <span class="list-divider" aria-hidden="true"></span>
      <li><a href="#">Pack Box + Mobile</a></li>
      <span class="list-divider" aria-hidden="true"></span>
      <li><a href="#">TV</a></li>
    </ul>

    <ul class="footer-bottom-social">
      <li><a href="#">
        <img src="fb.png" alt="facebook" />
      </a></li>
      <li><a href="#">
        <img src="twt.png" alt="tweeter" />
      </a></li>
      <li><a href="#">
        <img src="yt.png" alt="youtube" />
      </a></li>
    </ul>
  </nav>
</footer>`,
};
</script>
