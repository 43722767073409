<Header name="Modal" legend="Classic modal overlay" />

<Alert type="info">
  You'll need javascript to make it work, here is a potential implementation for an unique modal:
  <Prism language="js" source={`
    const modal = document.querySelector('.modal')

    document.querySelector('#btn-modal-open').addEventListener('click', () => {
      modal.showModal()
    })

    document.querySelectorAll('.btn-modal-close').forEach((btn) => {
      btn.addEventListener('click', () => {
        modal.close()
      })
    })
  `} />
</Alert>

<Alert type="info" class="m-t-m">
  the <code>autofocus</code> attribute has been added to the "Accept" button here
  so that it gains focus when the modal is opened.
  Feel free to add this attribute to whichever element you believe will best suit your needs
  but note that it should to match the element providing the "expected" action.
</Alert>

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="modals" />
</section>

<script lang="ts">
import { onMount } from 'svelte'
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Header from '../../docs/Header.svelte'
import Alert from '../../components/Alert.svelte'
import Prism from 'svelte-prism'

onMount(() => {
  const modal = document.querySelector<HTMLDialogElement>('.modal')

  document.querySelector('#btn-modal-open').addEventListener('click', () => {
    modal.showModal()
  })

  document.querySelectorAll('.btn-modal-close').forEach((btn) => {
    btn.addEventListener('click', () => {
      modal.close()
    })
  })
})

const exampleCode: Record<string, string> = {
  usage: `
<button class="btn btn-primary" id="btn-modal-open">
  Show Modal
</button>

<dialog class="modal">
  <section class="modal-body">
    <header class="modal-header">
      <h3 class="modal-title">Title</h3>
      <button class="modal-cross btn-modal-close" aria-label="fermer">
        <svg fill="currentColor" width="30" height="30" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3.71,12.29a1,1,0,0,1,0,1.42,1,1,0,0,1-1.42,0L12,
            13.42,9.71,15.71a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L10.58,12,8.29,9.71A1,1,0,0,1,9.71,8.29L12,
            10.58l2.29-2.29a1,1,0,0,1,1.42,1.42L13.42,12Z"></path>
        </svg>
      </button>
    </header>
    <div class="modal-content">
      <p>
        dog ipsum hooman chimken heckin walkies teefs heck danger noodle sploot pats woofer toe beans loaf woofer
        pupper blop chonk chimken sploot tippy taps chimken boop boop fluffer blep mahm yapper woofer derp
        bamboozle pupper smol blop
      </p>
      <p>
        loaf floof tippy taps cronch danger noodle gib boop smol gib floof heckin pupperino bork pupperino
        sploot chonk pupperino floofer pats fluffer awoo teefs pupperino cronch boof sleppy smol
      </p>
    </div>
    <footer class="modal-footer">
      <button autofocus class="btn btn-primary">
        Accept
      </button>
      <button class="btn btn-secondary-outline btn-modal-close">
        Close
      </button>
    </footer>
  </section>
</dialog>
`,}
</script>
