<footer class="footer">
  <section class="footer-columns">
  </section>

  <nav class="footer-bottom">
    <figure class="footer-brand">
      <img src="sfr.svg" alt="logo SFR" />
    </figure>

    <ul class="footer-bottom-links">
      <li><a href="https://gitlab.sd-sfr.fr/sma/tricss/"
             target="_blank">
        Gitlab
      </a></li>
      <span class="list-divider" aria-hidden="true"></span>
      <li><a href="https://gitlab.sd-sfr.fr/sma/tricss/-/issues"
             target="_blank">
        Issues
      </a></li>
      <span class="list-divider" aria-hidden="true"></span>
      <li><a href="https://gitlab.sd-sfr.fr/sma/tricss/-/releases"
             target="_blank">
        Releases
      </a></li>
    </ul>

    <ul class="footer-bottom-social">
    </ul>
  </nav>
</footer>
