<script lang="ts">
  import { link } from 'svelte-spa-router'
  import Badge from '../../components/Badge.svelte'
  import Divider from '../../components/Divider.svelte'
  import Alert from '../../components/Alert.svelte'
</script>

<svelte:head>
  <title>TRICSS | Documentation | Utilities</title>
</svelte:head>

<header>
  <Badge type="primary">
    <h2 class="margin-bottom-small">Utilities</h2>
  </Badge>
</header>

<p>Values:</p>
<ol class="list">
  <li><code>xs</code></li>
  <li><code>sm</code></li>
  <li><code>base</code></li>
  <li><code>m</code></li>
  <li><code>l</code></li>
  <li><code>xl</code></li>
  <li><code>2xl</code></li>
  <li><code>3xl</code></li>
</ol>

<Divider />

<section>
  <h2>Color</h2>
  <p>Change the color of the text, background or borders</p>
  <p>See <a href="/guides/colors" use:link>colors</a> for details</p>

  <Divider />

  <ul class="list">
    <li><code>text-$color[-hover]</code></li>
    <li><code>bg-$color[-hover]</code></li>
    <li><code>border-$color[-hover]</code></li>
  </ul>
</section>

<Divider />

<section>
  <h2>Spacing</h2>
  <p>Set margins or padding</p>
  <Alert type="info">
    Those classes will downsize under a certain threshold, use variant "-f" to prevent id
  </Alert>

  <p>Directions:</p>
  <ul class="list">
    <li><code>t</code> for top</li>
    <li><code>r</code> for right</li>
    <li><code>b</code> for bottom</li>
    <li><code>l</code> for left</li>
    <li><code>x</code> for horizontally or "inline"</li>
    <li><code>y</code> for vertically or "block"</li>
  </ul>

  <p>Flows:</p>
  <ul class="list">
    <li><code>in</code> for inline (logical properties)</li>
    <li><code>bl</code> for block (logical properties)</li>
  </ul>

  <Divider />

  <ul class="list">
    <li><code>m[-$direction]-$value|none|auto[-f]</code></li>
    <li><code>p[-$direction]-$value|none[-f]</code></li>
    <li><code>m-$flow-start|end-$value|none|auto[-f]</code></li>
    <li><code>p-$flow-start|end-$value|none|auto[-f]</code></li>
    <li><code>gap-$value|none[-f]</code></li>
  </ul>
</section>

<Divider />

<section>
  <h2>Typography</h2>
  <p>Change the size, weight, and other font properties of the text</p>

  <Divider />

  <ul class="list">
    <li><code>text-$value</code></li>
    <li><code>text-start|left|center|right|end|justify</code></li>
    <li><code>deco-none</code></li>
    <li><code>text-light|weight-m|bold|bolder</code></li>
  </ul>
</section>

<Divider />

<section>
  <h2>Border</h2>
  <Alert type="info">
    Modifiers need to be used with the initial <code>border</code> class
    (except <code>border-none</code>)
  </Alert>

  <Divider />

  <ul class="list">
    <li><code>border</code></li>
    <li><code>border-none</code></li>
    <li><code>rounded</code></li>
    <li><code>rounded-xs|sm|full</code></li>
    <li><code>squared</code></li>
    <li><code>rounded-top|bottom</code></li>
  </ul>
</section>

<Divider />

<section>
  <h2>Display</h2>

  <Divider />

  <ul class="list">
    <li><code>[d-]inline|inline-block|block|flex|none|grid</code></li>
    <li><code>list-inline</code></li>
  </ul>

  <h3>Hide elements based on viewport width</h3>

  <ul class="list">
    <li><code>hide-m</code></li>
    <li><code>hide-sm</code></li>
  </ul>

  <Divider />

  <h3>Flex</h3>

  <Alert type="info">
    flex direction classes come with `display: flex`, so they can be used on their own
  </Alert>

  <Divider />

  <ul class="list">
    <li><code>flex-row|col[-reverse]</code></li>
    <li><code>[flex-]wrap</code></li>
  </ul>

  <h3>Align items</h3>

  <p>Values:</p>
  <ul class="list">
    <li><code>stretch</code></li>
    <li><code>center</code></li>
    <li><code>start</code> for flex-start</li>
    <li><code>end</code> for flex-end</li>
    <li><code>baseline</code> for baseline</li>
  </ul>

  <Divider />

  <ul class="list">
    <li><code>align-$value</code></li>
    <li><code>ai-$value</code></li>
  </ul>

  <h3>Justify content</h3>

  <p>Values:</p>
  <ul class="list">
    <li><code>between</code> for space-between</li>
    <li><code>center</code></li>
    <li><code>start</code> for flex-start</li>
    <li><code>end</code> for flex-end</li>
    <li><code>evenly</code> for space-evenly</li>
  </ul>

  <Divider />

  <ul class="list">
    <li><code>justify-$value</code></li>
    <li><code>jc-$value</code></li>
  </ul>

  <h3>Position</h3>

  <p>Values:</p>
  <ul class="list">
    <li><code>static</code></li>
    <li><code>relative</code></li>
    <li><code>absolute</code></li>
    <li><code>fixed</code></li>
    <li><code>sticky</code></li>
  </ul>

  <Divider />

  <ul class="list">
    <li><code>$value</code></li>
    <li><code>fixed-$direction</code></li>
  </ul>
</section>

<Divider />

<section>
  <h2>Shadow</h2>

  <Divider />

  <ul class="list">
    <li><code>shadow[-hover]</code></li>
    <li><code>no-shadow</code></li>
  </ul>
</section>

<section>
  <h2>Size</h2>
  <p>Set width and height</p>

  <Divider />

  <ul class="list">
    <li><code>h-$values|none|full|fit-content</code></li>
    <li><code>w-$values|none|full|fit-content</code></li>
  </ul>
</section>

<section>
  <h2>Misc</h2>

  <Divider />

  <ul class="list">
    <li><code>pointer</code></li>
  </ul>
</section>
