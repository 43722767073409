import Home from './pages/Home.svelte';
import Start from './pages/Start.svelte';
import Example from './pages/Example.svelte';
import Component from './pages/Component.svelte';
import Container from './pages/layout/Container.svelte';
import Colors from './pages/guides/Colors.svelte';
import Utils from './pages/guides/Utils.svelte';
import Dark from './pages/guides/Dark.svelte';
export default {
    '/': Home,
    '/start': Start,
    '/example': Example,
    '/components/:component': Component,
    '/layout/container': Container,
    '/guides/colors': Colors,
    '/guides/utilities': Utils,
    '/guides/dark-mode': Dark,
};
