<script lang="ts">
  import Header from '../docs/Header.svelte'
  import Prism from 'svelte-prism'

  const cssImportCode: string = `<script>
  import 'tricss/dist/style.min.css'
<\/script>`
</script>

<Header name="Start"
        legend="How to install and use TRICSS" />

<div>
  There are two stylesheets available with their minified counterpart:
  <ul>
    <li><code>style[.min].css</code> - utils, base rules and components, will affect style in place</li>
    <li><code>utils[.min].css</code> - Contains all the utilities classes and theme custom properties</li>
  </ul>
</div>

<div class="divider" aria-hidden="true"></div>

<p class="m-y-2xl">
  Install TRICSS
  <Prism class="border padding-small"
         source="npm i git@gitlab.sd-sfr.fr:sma/tricss" />
</p>

<p class="m-y-2xl">
  Import the stylesheet into your HTML file:
  <Prism language="html"
          source='<link rel="stylesheet" href="node_modules/tricss/dist/style.min.css">' />
</p>
<p class="m-y-2xl">
  Or with a JS based Framework:
  <Prism class="border padding-small"
          language="html"
          source={cssImportCode} />
</p>
