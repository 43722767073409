<Header name="Navbar"
        legend="Responsive navigation header" />

<Alert type="info" icon={false}>
  <p>
    An hidden checkbox with an unique id starting with
    <code>"collapsible-checkbox"</code>
    is needed to toggle menu in responsive mode.
  </p>
  <p>
    You may "fix" the navbar ot the top by adding the <code>.navbar-fixed</code> class
  </p>
  <p>
    The <code>.active</code> class is used to style the relevant link
  </p>
</Alert>

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="navbars" />
</section>

<script lang="ts">
import Header from '../../docs/Header.svelte'
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Alert from '../../components/Alert.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<header class="navbar">
  <h1 class="navbar-brand">
    <a href="#">Brand</a>
  </h1>

  <div class="navbar-menu">
    <input id="collapsible-checkbox-a" type="checkbox">
    <label for="collapsible-checkbox-a"
            class="menu-collapser"
            aria-label="Afficher/Cacher le menu">
      <span aria-hidden="true"></span>
    </label>

    <nav class="navbar-menu-start">
      <ul>
        <li><a href="#" class="active">Installation</a></li>
        <span class="list-divider" aria-hidden="true"></span>
        <li><a href="https://gitlab.sd-sfr.fr/sma/tricss" target="_blank">
          Gitlab
        </a></li>
      </ul>
    </nav>

    <form class="navbar-menu-end">
      <input type="search" placeholder="Rechercher" />
    </form>
  </div>
</header>`,
logo: `
<header class="navbar">
  <figure class="navbar-brand">
    <a href="#">
      <img src="sfr.svg" alt="logo SFR" />
    </a>
  </figure>

  <div class="navbar-menu">
    <input id="collapsible-checkbox-b" type="checkbox">
    <label for="collapsible-checkbox-b"
            class="menu-collapser"
            aria-label="Afficher/Cacher le menu">
      <span aria-hidden="true"></span>
    </label>

    <nav class="navbar-menu-start">
      <ul>
        <li><a href="#" class="active">Installation</a></li>
        <span class="list-divider" aria-hidden="true"></span>
        <li><a href="https://gitlab.sd-sfr.fr/sma/tricss" target="_blank">
          Gitlab
        </a></li>
      </ul>
    </nav>

    <form class="navbar-menu-end">
      <input type="search" placeholder="Rechercher" />
    </form>
  </div>
</header>`
};
</script>
