<Header name="Radio" />

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="forms" />
</section>

<script lang="ts">
import Header from '../../docs/Header.svelte'
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'


const exampleCode: Record<string, string> = {
  usage: `
<div class="m-b-base">Best 2022 World Cup player</div>
<label>
  <input type="radio" name="answer" value="mbappé">
  Mbappé
</label>
<label>
  <input type="radio" name="answer" value="griezmann">
  Griezmann
</label>
<label>
  <input type="radio" name="answer" value="messi" disabled>
  Messi
</label>`
}
</script>
