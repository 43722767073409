<script lang="ts">
  export let label: string = ''
  export let componentSource: string = ''
  export let folder: string = 'components'

  const SOURCE_PATH = `https://gitlab.sd-sfr.fr/sma/tricss/-/blob/main/src/${folder}/_`
</script>

{#if label}
  <h4>
    <span aria-hidden>-</span> {label}
  </h4>
{/if}

<p class="p-l m-t-xl">
  Source code:
  <a href="{SOURCE_PATH}{componentSource}.scss" target="_blank">
    <code>{componentSource}.scss</code>
  </a>
</p>
