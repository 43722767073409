<script lang="ts">
  import Badge from '../../components/Badge.svelte'
</script>

<svelte:head>
  <title>TRICSS | Documentation | Colors</title>
</svelte:head>

<header>
  <Badge type="primary">
    <h2 class="margin-bottom-small">Colors</h2>
  </Badge>
</header>

<div class="divider" aria-hidden="true"></div>

<p>
  "Theme" colors vary in dark theme.
</p>
<div>
  You can use all of these colors with utils classes :
  <ul class="list">
    <li><code>text-$color[-hover]</code></li>
    <li><code>bg-$color[-hover]</code></li>
    <li><code>border-$color[-hover]</code></li>
  </ul>
</div>

<section class="colors">
  <h2>Theme</h2>
  <div class="flex text-white">
    <div class="bg-primary">primary</div>
    <div class="bg-secondary">secondary</div>
    <div class="bg-tertiary">tertiary</div>
    <div class="bg-info">info</div>
    <div class="bg-warning">warning</div>
    <div class="bg-success">success</div>
    <div class="bg-danger">danger</div>
  </div>
  <div class="flex text-black">
    <div class="bg-info-light">info-light</div>
    <div class="bg-warning-light">warning-light</div>
    <div class="bg-success-light">success-light</div>
    <div class="bg-danger-light">danger-light</div>
  </div>
</section>

<section class="colors">
  <h2>Constants</h2>
  <div class="flex">
    <div class="bg-grey-light text-black">grey-light</div>
    <div class="bg-grey-lighter text-black">grey-lighter</div>
    <div class="bg-black-light text-white">black-light</div>
    <div class="bg-red-light">red-light</div>
    <div class="bg-red-lighter text-black">red-lighter</div>
    <div class="bg-blue-light">blue-light</div>
  </div>
  <div class="flex">
    <div class="bg-white text-black">white</div>
    <div class="bg-black text-white">black</div>
    <div class="bg-grey">grey</div>
    <div class="bg-yellow">yellow</div>
    <div class="bg-green">green</div>
    <div class="bg-blue text-white">blue</div>
    <div class="bg-pink">pink</div>
    <div class="bg-purple text-white">purple</div>
  </div>
  <div class="flex text-white">
    <div class="bg-purple-dark">purple-dark</div>
    <div class="bg-teal">teal</div>
    <div class="bg-green-dark">green-dark</div>
  </div>
</section>

<style lang="scss">
@import 'tricss/src/utils/spacings';
@import 'tricss/src/utils/typography';

.colors {
  .flex {
    flex-wrap: wrap;
    & > div {
      @extend .p-3xl, .text-center;
      width: 100px;
      box-sizing: content-box;
    }
  }
}
</style>
