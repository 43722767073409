<Header name="Select" />

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="forms" />
</section>

<script lang="ts">
import Header from '../../docs/Header.svelte'
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<select>
  <option>A</option>
  <option>B</option>
  <option>C</option>
  <option>D</option>
</select>

<select disabled>
  <option>A</option>
  <option>B</option>
  <option>C</option>
  <option>D</option>
</select>
`,
sizes: `
<select>
  <option>A</option>
  <option>B</option>
  <option>C</option>
  <option>D</option>
</select>

<select class="select-sm">
  <option>A</option>
  <option>B</option>
  <option>C</option>
  <option>D</option>
</select>

<select class="select-xs">
  <option>A</option>
  <option>B</option>
  <option>C</option>
  <option>D</option>
</select>
`,
}
</script>
