<script lang="ts">
  import { link } from 'svelte-spa-router'
  import active from 'svelte-spa-router/active'
  import Badge from '../components/Badge.svelte'

  export let items: string[] = []
  export let updated: string[] = []
  export let newComponents: string[] = []
</script>

{#each items as item}
  <li>
    <a href="/components/{item}"
       use:link use:active>
      {item}
      {#if updated.includes(item)}
        <Badge type="secondary" size="sm">
          Updated
        </Badge>
      {:else if newComponents.includes(item)}
        <Badge type="success" size="sm">New</Badge>
      {/if}
    </a>
  </li>
{/each}
