<Header name="Button" legend="The Classic button" />

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="buttons" />
</section>

<script lang="ts">
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Header from '../../docs/Header.svelte'

const exampleCode: Record<string, string> = {
  classic: `
<p>
  <button class="btn btn-default">
    Default
  </button>
  <button class="btn btn-primary">
    primary
  </button>
  <button class="btn btn-secondary">
    secondary
  </button>
  <button class="btn btn-tertiary">
    tertiary
  </button>
</p>
<p>
  <button class="btn btn-info">
    info
  </button>
  <button class="btn btn-warning">
    warning
  </button>
  <button class="btn btn-danger">
    danger
  </button>
  <button class="btn btn-success">
    success
  </button>
</p>`,
  outlined: `
  <button class="btn btn-info-outline">
    info
  </button>
<button class="btn btn-primary-outline">
  primary outline
</button>
<button class="btn btn-secondary-outline">
  secondary outline
</button>
<button class="btn btn-success-outline">
  success outline
</button>
<button class="btn btn-warning-outline">
  warning outline
</button>
<button class="btn btn-danger-outline">
  danger outline
</button>`,
  states: `
<p>
  <button class="btn btn-default" disabled>
    Default
  </button>
  <button class="btn btn-primary" disabled>
    primary
  </button>
  <button class="btn btn-secondary" disabled>
    secondary
  </button>
  <button class="btn btn-tertiary" disabled>
    tertiary
  </button>
</p>
<p>
  <button class="btn btn-info" disabled>
    info
  </button>
  <button class="btn btn-warning" disabled>
    warning
  </button>
  <button class="btn btn-danger" disabled>
    danger
  </button>
  <button class="btn btn-success" disabled>
    success
  </button>
</p>
`,
  links: `
<a class="btn btn-secondary"
    href="https://gitlab.sd-sfr.fr/sma/tricss" target="_blank">
  Gitlab
</a>`,
  customized: `
<button class="btn btn-secondary squared">
  squared
</button>
<button class="btn btn-default border border-purple text-purple">
  default
</button>
`}
</script>
