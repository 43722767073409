<script lang="ts">
  import Prism from 'svelte-prism'
  import Button from '../components/Button.svelte'

  export let title: string = ''
  export let code: string

  const isSecureContext = location.hostname === 'localhost'
    || location.protocol === 'https:'

  async function copyCode() {
    await navigator.clipboard.writeText(code)
  }
</script>

<section>
  {#if title}
    <h3 class="margin-bottom-small">- {title}</h3>
  {/if}
  <div>
    <section class="example-demo p-base">
      {@html code}
    </section>
    <section class="example-code p-base">
      <Prism language="html" source={code} />
      {#if isSecureContext}
        <Button class="btn-copy" on:click={copyCode}>
          Copy Code
        </Button>
      {/if}
    </section>
  </div>
</section>

<style>
h3 {
  text-transform: capitalize;
}
:global(.btn-copy) {
  float: right;
}
</style>
