<script lang="ts">
  import Router, { link } from 'svelte-spa-router'
  import active from 'svelte-spa-router/active'
  import routes from './routes'
  import Nav from './components/Nav.svelte'
  import Footer from './components/Footer.svelte'
  import ListLinks from './docs/ListLinks.svelte'
  import ToTop from './components/ToTop.svelte'
  import 'prismjs/themes/prism-okaidia.min.css'

  const topComponents = [
    'Alert',
    'Breadcrumb',
    'Button',
    'Card',
    'Collapsible',
    'Divider',
    'Footer',
  ]

  const formComponents = [
    'Checkbox',
    'Input',
    'Radio',
    'Select',
    'Switch',
  ]

  const bottomComponents = [
    'List',
    'Modal',
    'Navbar',
    'Table',
  ]

  const updated = [
  ]

  const newComponents = [
    'Modal',
  ]
</script>

<Nav />

<div class="main-layout p-base">
  <aside class="left-nav container">
    <nav class="sticky">
      <h4 class="text-secondary">Installation</h4>
      <ul class="list">
        <li><a href="/start" use:link use:active>Start</a></li>
        <li><a href="/example" use:link use:active>Example</a></li>
      </ul>

      <h4 class="text-secondary">Components</h4>
      <ul class="list">
        <ListLinks items={topComponents}
                  {updated} {newComponents} />
        <li>Form</li>
        <ul class="list">

          <ListLinks items={formComponents}
                    {updated} {newComponents} />
        </ul>
        <ListLinks items={bottomComponents}
                  {updated} {newComponents} />
      </ul>

      <h4 class="text-secondary">Layout</h4>
      <ul class="list">
        <li><a href="/layout/container" use:link use:active>Container</a></li>
      </ul>

      <h4 class="text-secondary">Guides</h4>
      <ul class="list">
        <li>
          <a href="/guides/colors" use:link use:active>
            Colors
          </a>
        </li>
        <li>
          <a href="/guides/utilities" use:link use:active>
            Utilities
          </a>
        </li>
        <li>
          <a href="/guides/dark-mode" use:link use:active>
            Dark Mode
          </a>
        </li>
      </ul>
    </nav>
  </aside>

  <main class="container">
    <Router {routes} />
  </main>
</div>

<Footer />
<ToTop />

<style lang="scss" global>
@import 'tricss/src/utils/colors';

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
}

.main-layout {
  display: flex;
  gap: 1rem;
  .left-nav {
    a {
      text-decoration: none;
      &:hover, &.active {
        @extend .text-primary;
      }
    }
  }
  main {
    flex-grow: 1;
  }
}
</style>
