<script lang="ts" context="module">
  let count: number = 0
</script>

<script lang="ts">
  import { onMount, createEventDispatcher } from 'svelte'

  export let checked: boolean = false
  export let disabled: boolean = false
  export let label: string = ''
  export let onLabel: string = null

  const dispatch = createEventDispatcher()
  let id: number

  onMount(() => {
    id = count++
  })

  $: displayedLabel = checked && onLabel !== null
    ? onLabel : label
  $: domId = `theme-${id}`
</script>

{#if label}
  <label for={domId} class="text-sm">
    {displayedLabel}
  </label>
{/if}
<label {...$$restProps}
       class="{$$restProps.class ?? ''} switch">
  <input type="checkbox" id={domId}
         bind:checked={checked}
         {disabled}
         on:change
         on:change={dispatch.bind(null, 'toggle', checked)}
         on:keyup
         on:focus
         on:blur>
  <span class="slider" aria-hidden="true"></span>
</label>
