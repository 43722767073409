<script lang="ts">
  import type { TricssColor, TricssSize } from '../types'

  export let type: TricssColor = 'secondary'
  export let textColor: TricssColor = 'white'
  export let size: TricssSize = 'm'

  function sizeClasses(): string {
    switch(size) {
      case 'sm':
        return 'text-sm p-x-sm p-y-xs'
      case 'm':
        return 'p-x-l p-y-base'
      case 'l':
        return 'text-l p-x-xl p-y-l'
    }
  }
</script>

<span {...$$restProps}
      class="{$$restProps.class ?? ''} inline-block {sizeClasses()}
            rounded bg-{type} text-{textColor}">
  <slot />
</span>
