<script lang="ts">
  import { onMount } from 'svelte'
  import Switch from './Switch.svelte'

  enum Theme {
    OS = 'OS',
    LIGHT = 'light',
    DARK = 'dark',
  }

  localStorage.theme ??= Theme.OS

  let darkTheme = localStorage.theme === Theme.DARK

  onMount(() => {
    if (localStorage.theme === Theme.OS) {
      darkTheme = window?.matchMedia('(prefers-color-scheme: dark)').matches
    }
  })

  function toggleHandler() {
    localStorage.theme = darkTheme ? Theme.DARK : Theme.LIGHT
  }

  $: document.documentElement.classList.toggle('dark', darkTheme)
</script>

<Switch {...$$restProps}
        label="☀️ Theme" onLabel="🌙 Theme"
        bind:checked={darkTheme}
        on:toggle={toggleHandler} />
