<script lang="ts">
  import Badge from '../components/Badge.svelte'

  export let name: string
  export let legend: string = ''
</script>

<svelte:head>
	<title>TRICSS | Documentation | {name}</title>
</svelte:head>

<header>
  <Badge type="primary">
    <h2 class="margin-bottom-small">{name}</h2>
  </Badge>
  <p>{legend}</p>
</header>

<div class="divider" aria-hidden="true"></div>

<style>
header p {
  font-style: italic;
}
</style>
