<script lang="ts">
  import ThemeToggler from './ThemeToggler.svelte'
  import { push } from 'svelte-spa-router'

  const components = [
    'Breadcrumb',
    'Button',
    'Card',
    'Collapsible',
    'Divider',
    'Footer',
    'Checkbox',
    'Input',
    'Radio',
    'Select',
    'Switch',
    'List',
    'Navbar',
    'Table',
  ]
  const layout = [
    'Container'
  ]
  const guides = [
    'Colors',
    'Utilities',
    'Dark Mode',
  ]

  const searchItems = components.concat(layout).concat(guides)
  let searchValue = ''

  function formatForUrl(val: string): string {
    return val.toLowerCase().replace(' ', '-')
  }

  function searchHandler() {
    if (searchItems.includes(searchValue)) {
      if (components.includes(searchValue)) {
        push(`/components/${searchValue}`)
      } else if (guides.includes(searchValue)) {
        push(`/guides/${formatForUrl(searchValue)}`)
      } else if (layout.includes(searchValue)) {
        push(`/layout/${formatForUrl(searchValue)}`)
      }

      searchValue = ''
    }
  }
</script>

<header class="navbar">
  <h1 class="navbar-brand text-primary">
    <a href="/#/">
      <span class="text-primary">TRI</span><span class="text-success">CSS</span>
    </a>
  </h1>

  <div class="navbar-menu">
    <input id="collapsible-checkbox" type="checkbox">
    <label for="collapsible-checkbox"
            class="menu-collapser"
            aria-label="Afficher/Cacher le menu">
      <span aria-hidden="true"></span>
    </label>

    <nav class="navbar-menu-start">
      <ul>
        <li><a href="#" class="active">Installation</a></li>
        <span class="list-divider" aria-hidden="true"></span>
        <li><a href="https://gitlab.sd-sfr.fr/sma/tricss" target="_blank">
          Gitlab
        </a></li>
      </ul>
    </nav>

    <form on:submit|preventDefault={searchHandler}>
      <input bind:value={searchValue} on:change={searchHandler}
             type="search" placeholder="Search" list="search" />
      <datalist id="search">
        {#each searchItems as item}
          <option value={item}>
        {/each}
      </datalist>
    </form>

    <form class="navbar-menu-end p-l">
      <ThemeToggler />
    </form>
  </div>
</header>
