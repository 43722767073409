<Header name="Breadcrumb"
        legend="A simple breadcrumb component to improve your navigation experience" />

<section>
  <Example code={exampleCode.usage} />
</section>

<section>
  <Source componentSource="breadcrumbs" />
</section>

<script lang="ts">
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Header from '../../docs/Header.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<nav class="breadcrumb">
  <ol>
    <li><a href="#">Documentation</a></li>
    <li><a href="#">Components</a></li>
    <li>Breadcrumb</li>
  </ol>
</nav>`}
</script>
