<Header name="Checkbox" />

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="forms" />
</section>

<script lang="ts">
import Header from '../../docs/Header.svelte'
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<label class="checkbox-container">
  Remember me
  <input type="checkbox" />
  <span class="checkmark"></span>
</label>
`,
  states: `
<label class="checkbox-container">
  Remember me
  <input type="checkbox" disabled />
  <span class="checkmark"></span>
</label>`
};
</script>
