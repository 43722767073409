<script lang="ts">
  import type { TricssType } from '../types'
  export let type: TricssType = 'primary'
  export let outlined: boolean = false
  export let block: boolean = false

  $: btnClass = `btn btn-${type}${outlined ? '-outline' : ''}`
</script>

<button {...$$restProps}
        on:click|preventDefault
        class="{$$restProps.class ?? ''} {btnClass}"
        class:w-full={block}>
  <slot />
</button>
