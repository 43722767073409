<Header name="Table"
        legend="Display sets of data" />

<Alert type="info" icon={false}>
  You may customize tables with <code>bg-$color</code>,
  <code>bg-$color-hover</code> and <code>text-$color</code> classes
</Alert>

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="tables" />
</section>

<script lang="ts">
import Header from '../../docs/Header.svelte'
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Alert from '../../components/Alert.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<table>
  <thead>
    <tr>
      <th>Rank</th>
      <th>Name</th>
      <th>Country</th>
    </tr>
    <tr class="spacer" aria-hidden="true">
      <td></td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>#1</td>
      <td>Carlos Alcaraz</td>
      <td><img src="https://flagcdn.com/w40/es.png"></td>
    </tr>
    <tr>
      <td>#2</td>
      <td>Rafael Nadal</td>
      <td><img src="https://flagcdn.com/w40/es.png"></td>
    </tr>
    <tr>
      <td>#3</td>
      <td>Casper Ruud</td>
      <td><img src="https://flagcdn.com/w40/no.png"></td>
    </tr>
    <tr>
      <td>#4</td>
      <td>Stefanos Tsitsipas</td>
      <td><img src="https://flagcdn.com/w40/gr.png"></td>
    </tr>
    <tr>
      <td>#5</td>
      <td>Novak Djokovic</td>
      <td><img src="https://flagcdn.com/w40/rs.png"></td>
    </tr>
  </tbody>
</table>`,
  colors: `
<table>
  <thead>
    <tr>
      <th class="bg-danger-light text-primary">Rank</th>
      <th class="bg-black">Name</th>
      <th class="bg-tertiary text-black">Country</th>
    </tr>
    <tr class="spacer" aria-hidden="true">
      <td></td>
    </tr>
  </thead>
  <tbody>
    <tr class="bg-grey-hover">
      <td>#1</td>
      <td>Carlos Alcaraz</td>
      <td><img src="https://flagcdn.com/w40/es.png"></td>
    </tr>
    <tr class="bg-grey-hover">
      <td>#2</td>
      <td>Rafael Nadal</td>
      <td><img src="https://flagcdn.com/w40/es.png"></td>
    </tr>
    <tr class="bg-grey-hover">
      <td>#3</td>
      <td>Casper Ruud</td>
      <td><img src="https://flagcdn.com/w40/no.png"></td>
    </tr>
    <tr class="bg-grey-hover">
      <td>#4</td>
      <td>Stefanos Tsitsipas</td>
      <td><img src="https://flagcdn.com/w40/gr.png"></td>
    </tr>
    <tr class="bg-grey-hover">
      <td>#5</td>
      <td>Novak Djokovic</td>
      <td><img src="https://flagcdn.com/w40/rs.png"></td>
    </tr>
  </tbody>
</table>`
};
</script>
