<Header name="Container" />

<section>
  {#each Object.keys(exampleCode) as section}
    <Example title={section} code={exampleCode[section]} />
  {/each}
</section>

<section>
  <Source componentSource="container" folder="layout" />
</section>

<script lang="ts">
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'
import Header from '../../docs/Header.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<div class="container">
    <h2>Title</h2>
    <p>
      Doggo ipsum pupper very taste wow heckin thicc big ol heckin, woofer bork borkf puggo doge.
    </p>
</div>`
}
</script>
