<script lang="ts">
  import type { TricssType } from '../types'
  import cn from 'classnames'

  type AlertType = Exclude<TricssType, 'primary' | 'secondary' | 'tertiary'>

  export let type: AlertType = null
  export let icon: boolean = true
  export let iconAriaLabel: string = null

  const iconAriaLabels: Record<AlertType, string> = {
    info: 'information',
    warning: 'attention',
    danger: 'erreur',
    success: 'succès',
  }
</script>

<div {...$$restProps}
     class={cn($$restProps.class, 'alert', { [`alert-${type}`]: type })}>
  {#if icon && type}
    <span class="alert-icon"
          aria-label={iconAriaLabel ?? (iconAriaLabels[type] ?? 'information')}>
    </span>
  {/if}
  <slot />
</div>
