<script lang="ts">
  import { link } from 'svelte-spa-router'
  import Badge from '../components/Badge.svelte'

  const VERSION = 'v3.4.0'
</script>

<svelte:head>
	<title>TRICSS | Documentation</title>
</svelte:head>

<div class="text-center">
  <h2 class="text-primary">TRICSS</h2>
  <p class="text-success p-l m-b-3xl">
    CSS famework for the <span class="text-primary">SFR</span> Design System
  </p>
  <p><code class="text-white bg-black p-y-base p-x-3xl rounded">
    $ npm i git@gitlab.sd-sfr.fr:sma/tricss
  </code></p>
  <p class="p-l m-t-3xl">
    <a class="btn btn-success" href="/start" use:link>
      Get Started
    </a>
    <a class="btn btn-default"
       href="https://gitlab.sd-sfr.fr/sma/tricss/-/releases"
       target="_blank">
      Changelog
    </a>
  </p>
  <div class="divider" aria-hidden="true"></div>
  <Badge textColor="success" size="l">
    {VERSION}
  </Badge>
</div>

