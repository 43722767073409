<Header name="Switch"
        legend="Switch between two opposing states" />

<section>
  <Example title="usage" code={exampleCode.usage} />
</section>

<section>
  <Source componentSource="forms" />
</section>

<script lang="ts">
import Header from '../../docs/Header.svelte'
import Example from '../../docs/Example.svelte'
import Source from '../../docs/Source.svelte'

const exampleCode: Record<string, string> = {
  usage: `
<p>
  <label for="switch-a">Label</label>
  <label class="switch">
    <input type="checkbox" id="switch-a">
    <span class="slider"></span>
  </label>
</p>

<p>
  <label for="switch-b">Label</label>
  <label class="switch">
    <input type="checkbox" id="switch-b" disabled>
    <span class="slider"></span>
  </label>
</p>

<p>
  <label for="switch-c">Label</label>
  <label class="switch">
    <input type="checkbox" id="switch-c" checked>
    <span class="slider"></span>
  </label>
</p>

<p>
  <label for="switch-d">Label</label>
  <label class="switch">
    <input type="checkbox" id="switch-d" checked disabled>
    <span class="slider"></span>
  </label>
</p>`,
};
</script>
