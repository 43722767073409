<button class="btn btn-primary p-x-base to-top"
        on:click={window.scrollTo.bind(null, { top: 0 })}
        aria-label="To top">
  ⌃
</button>

<style>
.to-top {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
</style>
